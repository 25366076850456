import * as React from "react"
import Parser from 'html-react-parser'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from "urql"
import Button from "@mui/material/Button"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { API_GATEWAY_API_KEY, GET_TERMS_ENDPOINT } from "constants/base"
import { Terms } from "gql/graphql"
import "react-quill/dist/quill.snow.css"

function TermPage() {
  const [terms, setTerms] = React.useState<Terms | null>(null)
  const [content, setContent] = React.useState("")
  const [button, setButton] = React.useState("")
  const [alert, setAlert] = React.useState(false)
  const [language, setLanguage] = React.useState("JP")
  const [error, setError] = React.useState("利用規約への同意に失敗しました")
  const [isUpdate, setIsUpdate] = React.useState(false)
  const [disabled, setDisabled] = React.useState(true)
  const navigate = useNavigate()

  const [result, executeQuery] = useQuery({
    query: /* GraphQL */`
      query getAcceptDate {
        getAccountData {
          termsAcceptDate
        }
        getTerms {
          content
          contentEn
          button
          buttonEn
          updateDate
        }
      }
    `,
  })

  const changeLanguage = () => {
    switch (language) {
      case "JP":
        setContent(terms?.content || '')
        setButton(terms?.button || '')
        setError("利用規約への同意に失敗しました")
        break
      case "EN":
        setContent(terms?.contentEn || '')
        setButton(terms?.buttonEn || '')
        setError("Failed to accept the terms of service")
        break
    }
  }

  React.useEffect(() => {
    try {
      fetch(GET_TERMS_ENDPOINT, {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_GATEWAY_API_KEY
        },
      }).then((res) => {
        res.json().then((res) => {
          setTerms({
            termsId: res.termsId,
            content: res.content,
            contentEn: res.contentEn,
            updateDate: res.updateDate,
            button: res.button,
            buttonEn: res.buttonEn,
          })
        })
      })
    } catch (e) {
      console.log("get terms error")
    }
  }, [])

  const [state, executeMutation] = useMutation( `mutation updateAcceptDate { updateAcceptDate }` )

  React.useEffect(() => {
    const termsAcceptDate = new Date(result.data?.getAccountData?.termsAcceptDate)
    const updateDate = new Date(result.data?.getTerms?.updateDate)
    setIsUpdate(updateDate > termsAcceptDate)
  }, [result, terms])

  React.useEffect(() => {
    changeLanguage()
  }, [language])

  React.useEffect(() => {
    changeLanguage()
  }, [terms])

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement
    if (Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <= 15) {
      setDisabled(false)
    }
  }

  const handleSubmit = () => {
    setAlert(false)
    if (disabled) {
      setAlert(true)
      return
    } else {
      executeMutation().then((res) => {
        if (res.error) {
          setAlert(true)
          return
        }
        navigate("/", { replace: true })
      }).catch(() => {
        setAlert(true)
      })
    }
  }


  return (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: '800px',
        height: '100%',
        boxSizing: 'border-box',
        padding: '20px',
        whiteSpace: 'wrap',
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {alert && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Stack direction="row" gap={2}>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => setLanguage("JP")}
        >
          {"日本語"}
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => setLanguage("EN")}
        >
          {"English"}
        </Button>
      </Stack>
      <Box
        className="ql-editor"
        sx={{
          margin: '20px 0',
          overflowY: 'auto',
        }}
        onScroll={handleScroll}
      >
        {Parser(String(content))}
      </Box>
      {isUpdate && (
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Button
            type="button"
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {button}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default TermPage
