/*

	お知らせ画面下部バナー 買いログTalk Self

*/
import * as React from "react"
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar"
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useQuery } from "urql"

export default function KailogTalkSelf() {
  // 買いログTalk selfシームレスログイン
  const [open, setOpen] = React.useState(false)
  const [result, executeQuery] = useQuery({
    query: /* GraphQL */ `
      query selfInterviewSeamlessLogin {
        selfInterviewSeamlessLogin
      }
    `,
    requestPolicy: 'network-only',
    pause: true,
  })

  React.useEffect(() => {
    if (result.data?.selfInterviewSeamlessLogin) {
      window.open(result.data?.selfInterviewSeamlessLogin, '_blank')
    }
  }, [result.data?.selfInterviewSeamlessLogin])

  React.useEffect(() => {
    if (result.error) {
      setOpen(true)
    }
  }, [result.error])

  return (
    <Box>
      <Stack
        sx={{
          m: 2,
          p: 1,
          borderBottom: '1px solid lightgray',
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          買いログTalk self
        </Typography>
        <Button
          variant="contained"
          color="info"
          onClick={() => executeQuery()}
        >
          決定
        </Button>
      </Stack>
      <Box p={2}>
        <Stack
          sx={{
            backgroundColor: "rgb(245, 245, 245)",
            height: '460px',
            overflowY: 'auto',
          }}
          alignItems="center"
          gap={4}
        >
          <Stack direction="column" alignItems="center" p={2}>
            <Box sx={{ maxWidth: '1250px', textAlign: 'center' }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '32px',
                  fontWeight: 'bold',
                  color: 'rgb(49, 106, 165)',
                  textAlign: 'center',
                }}
              >
                MAU30万人の買いログから対象者を抽出・選定し、
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '32px',
                  fontWeight: 'bold',
                  color: 'rgb(49, 106, 165)',
                  textAlign: 'center',
                  marginBottom: '16px',
                }}
              >
                実購買者の「生の声」をクイックに聴けるセルフインタビュー
              </Typography>
              <Typography>
                買いログTalk selfの画面から、簡単な操作でご希望の対象者属性、購入商品、インタビュー時間、日時等を指定。後日、実購買者とオンラインで対面して「生の声」を聴けます。
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            px={2}
            pb={6}
            gap={3}
            sx={{ maxWidth: '1250px' }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'rgb(49, 106, 165)',
                textAlign: 'center',
              }}
            >
              買いログTalk selfとは？
            </Typography>
            <Stack direction="row" alignItems="center" gap={4}>
              <Box
                component="img"
                sx={{
                  width: '60%',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                }}
                src="/information/kailog_talk_self/overview01.png"
              >
              </Box>
              <Box sx={{ width: '40%' }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    mb: '16px',
                  }}
                >
                  MAU約30万人の買いログから実購買者を抽出・選定できます。
                </Typography>
                <Typography>
                  MAU約30万人の「買いログ」データベースから、性別・年齢・居住エリアなどの属性と事前質問テンプレートで対象者を抽出。さらに購入商品をSKU単位(複数指定もOK)で指定します。これにより、インタビューの目的に合致した「実購買者」を選定できます。
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" gap={4}>
              <Box sx={{ width: '40%' }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    mb: '16px',
                  }}
                >
                  約束の時間に、選定した対象者にZoomでインタビューします。
                </Typography>
                <Typography>
                  選定された対象者には、インタビュー対象として ”当選” したことが通知されます。あとは、約束の日時にZoomでインタビューするだけ。実購買者の「生の声」を聴き出して、仮説の構築や検証に活用できます。
                </Typography>
              </Box>
              <Box
                component="img"
                sx={{
                  width: '60%',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                }}
                src="/information/kailog_talk_self/overview02.png"
              >
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            alignItems="center"
            px={2}
            py={6}
            sx={{
              backgroundColor: 'rgba(49, 106, 165, 0.9)',
              width: '100%',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'center',
              }}
            >
              対象者の抽出・選定～インタビュー日程調整～実施までを
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'center',
                mb: '16px',
              }}
            >
              WEB画面で
            </Typography>
            <Typography sx={{ color: 'white' }}>
              買いログTalk selfでは、対象者の抽出・選定からインタビュー実施の一連の流れを、ご自分でWEB画面から進められます。
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'center',
                mt: '32px',
                mb: '16px',
              }}
            >
              実施のフロー
            </Typography>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              sx={{
                width: '90%',
                maxWidth: '1250px',
              }}
            >
              <Box
                component="img"
                sx={{ width: '50%' }}
                src="/information/kailog_talk_self/flow01.png"
              >
              </Box>
              <Box
                component="img"
                sx={{ width: '50%' }}
                src="/information/kailog_talk_self/flow02.png"
              >
              </Box>
            </Stack>
          </Stack>
  
          <Stack
            direction="column"
            alignItems="center"
            px={2}
            py={6}
            gap={2}
            sx={{ width: '100%', maxWidth: '1250px' }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'rgb(49, 106, 165)',
                textAlign: 'center',
                mb: '16px',
              }}
            >
              買いログ Talk selfのメリット
            </Typography>
            <Stack direction="row" gap={2} sx={{ width: '100%' }}>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "rgba(63, 114, 166, 0.1)",
                  borderRadius: '8px',
                  width: '50%',
                  p: 2,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '48px',
                    height: '48px',
                    mr: '8px',
                  }}
                  src="/information/kailog_talk_self/icon-comment.svg"
                >
                </Box>
                <Box>
                  <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: 'rgb(49, 106, 165)',
                  }}>
                    実購買者に聴取できる
                  </Typography>
                  <Typography>
                    レシートとバーコードにより買物登録したユーザー(=ある商品の実購買者)の「生の声」を直接聴けます。
                  </Typography>
                </Box>
              </Stack>
  
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "rgba(63, 114, 166, 0.1)",
                  borderRadius: '8px',
                  width: '50%',
                  p: 2,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '48px',
                    height: '48px',
                    mr: '8px',
                  }}
                  src="/information/kailog_talk_self/icon-devices.svg"
                >
                </Box>
                <Box>
                  <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: 'rgb(49, 106, 165)',
                  }}>
                    クイックに実施できる
                  </Typography>
                  <Typography>
                    調査会社の営業担当とのやりとりをすることなく、WEB画面からご自分でクイックに準備・実施できます。
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack direction="row" gap={2} sx={{ width: '100%' }}>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "rgba(63, 114, 166, 0.1)",
                  borderRadius: '8px',
                  width: '50%',
                  p: 2,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '48px',
                    height: '48px',
                    mr: '8px',
                  }}
                  src="/information/kailog_talk_self/icon-savings.svg"
                >
                </Box>
                <Box>
                  <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: 'rgb(49, 106, 165)',
                  }}>
                    低コスト
                  </Typography>
                  <Typography>
                    セルフで対象者を抽出し、自らインタビューするため、低コストでの実施が可能です。
                  </Typography>
                </Box>
              </Stack>
  
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "rgba(63, 114, 166, 0.1)",
                  borderRadius: '8px',
                  width: '50%',
                  p: 2,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '48px',
                    height: '48px',
                    mr: '8px',
                  }}
                  src="/information/kailog_talk_self/icon-japan.svg"
                >
                </Box>
                <Box>
                  <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: 'rgb(49, 106, 165)',
                  }}>
                    日本全国の生活者が対象に
                  </Typography>
                  <Typography>
                    お買い物アプリ・CODEのユーザーが対象者になるため、日本全国の生活者とコンタクトできます。
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'rgb(49, 106, 165)',
                textAlign: 'center',
                mt: '32px',
              }}
            >
              買いログ Talk self時の様子
            </Typography>
            <Typography>
              対象者は自宅でリラックスした状態なので、カジュアルに話を聴くことができます。
            </Typography>
            <Stack
              direction="row"
              gap={2} sx={{
                width: '100%',
                backgroundColor: "rgba(63, 114, 166, 0.1)",
                borderRadius: '8px',
              }}
            >
              <Stack
                direction="column"
                sx={{
                  width: '33%',
                  p: 2,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    mb: '8px',
                  }}
                  src="/information/kailog_talk_self/interview01.png"
                >
                </Box>
                <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                  キッチンの冷蔵庫を見せてくれるシーン
                </Typography>
              </Stack>
              <Stack
                direction="column"
                sx={{
                  width: '33%',
                  p: 2,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    mb: '8px',
                  }}
                  src="/information/kailog_talk_self/interview02.png"
                >
                </Box>
                <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                  名前を忘れた商品を確認するシーン
                </Typography>
              </Stack>
              <Stack
                direction="column"
                sx={{
                  width: '33%',
                  p: 2,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    mb: '8px',
                  }}
                  src="/information/kailog_talk_self/interview03.png"
                >
                </Box>
                <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                  発売した新商品へユーザーからの第一声
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message="買いログTalk selfへのログインに失敗しました。"
      />
    </Box>
  )
}
