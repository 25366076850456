import Button from "@mui/material/Button"
import DownloadIcon from "@mui/icons-material/Download"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

export default function ManualDialog(props: {
  open: boolean,
  onClose: () => void,
}) {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
      <DialogTitle align="center">
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          操作マニュアル
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>操作マニュアル(PDF)をダウンロードできます</Typography>
        <List sx={{ my: 2 }}>
          <ListItem sx={{ borderTop: 1, borderBottom: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Stack direction="row" gap={2}>
                <PictureAsPdfIcon />
                <Typography>買いログポータル_操作マニュアル_20250106</Typography>
              </Stack>
              <Button
                variant="contained"
                color="info"
                href="/manual.pdf"
                target="_blank"
                download="買いログポータル_操作マニュアル_20250106.pdf"
              >
                <DownloadIcon />
                ダウンロード
              </Button>
            </Stack>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary"
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  )
}
