import * as React from "react"
import Badge from "@mui/material/Badge"
import HomeIcon from '@mui/icons-material/Home';
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Snackbar from "@mui/material/Snackbar"
import { Divider } from "@mui/material"
import EmailIcon from '@mui/icons-material/Email';
import ReviewIcon from "common/icons/ReviewIcon"
import { KailogViewUrl } from "constants/urls"
import { useQuery } from "urql"

// Const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
//   "&": { minWidth: "32px", justifyContent: "flex-start" },
// })) as typeof ListItemIcon

export default function GlobalMenu() {
  // 買いログTalk selfシームレスログイン
  const [open, setOpen] = React.useState(false)
  const [result, executeQuery] = useQuery({
    query: /* GraphQL */ `
      query selfInterviewSeamlessLogin {
        selfInterviewSeamlessLogin
      }
    `,
    requestPolicy: 'network-only',
    pause: true,
  })

  const [resultUnread] = useQuery({
    query: /* Graphql */ `
      query getUnreadSummary {
        getUnreadSummary {
          unreadInfoCnt
          uncheckedContactCnt
        }
      }`
    })

  React.useEffect(() => {
    if (result.data?.selfInterviewSeamlessLogin) {
      window.open(result.data?.selfInterviewSeamlessLogin, '_blank')
    }
  }, [result.data?.selfInterviewSeamlessLogin])

  React.useEffect(() => {
    if (result.error) {
      setOpen(true)
    }
  }, [result.error])

  return (
    <>
      <ListItemButton href="/information">
        <ListItemIcon>
          {resultUnread.data?.getUnreadSummary.unreadInfoCnt > 0 ? (
            <Badge badgeContent={resultUnread.data?.getUnreadSummary.unreadInfoCnt} color="error">
              <HomeIcon />
            </Badge>
          ) : (
            <HomeIcon />
          )}
        </ListItemIcon>
        <ListItemText primary="ホーム / お知らせ" />
      </ListItemButton>

      <ListItemButton href="/review">
        <ListItemIcon>
          <ReviewIcon width='1.5em' height='1.5em' fill='rgba(0, 0, 0, 0.54)' />
        </ListItemIcon>
        <ListItemText primary="口コミ・評価分析 LITE" />
      </ListItemButton>

      <Divider sx={{ my: 1 }} />

      <ListItemButton href={KailogViewUrl()} target="_blank">
        <ListItemIcon>
          <img src="/kailog-view_icon_512.png" width='21px' />
        </ListItemIcon>
        <ListItemText primary="買いログView" />
      </ListItemButton>
      
      <ListItemButton onClick={() => executeQuery()}>
        <ListItemIcon>
          <img src="/kailog-talk_icon_512.png" width='21px' />
        </ListItemIcon>
        <ListItemText primary="買いログTalk self" />
      </ListItemButton>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message="買いログTalk selfへのログインに失敗しました。"
      />
    </>
  )
}
