/*

	お知らせ画面下部バナー

*/
import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ServicePanels from './ServicePanels';
import { serviceItems } from './type';
import Carousel from 'react-material-ui-carousel'

// Service details.
import Example from './ServiceDetail/Example';
import KailogView from './ServiceDetail/KailogView';
import KailogTalkSelf from './ServiceDetail/KailogTalkSelf';
import Review from './ServiceDetail/Review';

export default function Banner() {
  const [selectedItem, setSelectedItem] = React.useState(serviceItems[0]);

  return (
    <Stack
      direction="column"
      alignContent="flex-start"
      sx={{
        m: '32px 48px 0 48px',
        p: '16px 0',
        gap: '16px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          メニュー
        </Typography>
        <Typography sx={{ ml: '16px' }}>
          生活者への各種リサーチや購買データ分析ツールのメニューを選択します
        </Typography>
      </Box>
      <Carousel
        autoPlay={false}
        index={selectedItem.index}
        indicators={false}
        navButtonsAlwaysVisible={false}
        animation="slide"
        sx={{
          height: "580px",
          backgroundColor: "white",
        }}
        navButtonsWrapperProps={{
          style: {
            height: "85%",
            top: "unset",
            bottom: "0",
            margin: "0 32px"
          }
        }}
        onChange={(now?: number) => {
          setSelectedItem(now ? serviceItems[now] : serviceItems[0])
        }}
      >
        <Review />
        <KailogView />
        <KailogTalkSelf />
      </Carousel>
      <ServicePanels
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </Stack>
  )
}
